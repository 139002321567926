@import "../../mixins.scss";

.pold {
  .menu {
    //z-index: 10;
    z-index: 130000;
    //z-index: 1300000000;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    border-left: 0 !important;
    //background-color: #4E49FC;
    // background-color: transparent;
    background-color: #2FBDDA;
    overflow: hidden;
    //width: calc(100% - 120px);
    //margin-left: 120px;
    position: fixed;
    display: none;
    left: 86px;
    height: 100vh;
    width: calc(100vw - 86px);


    &.toggled {
      display: block;
    }

    .footer-menu {
      display: flex;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 7vh;
      width: 100%;
      background: #2FBDDA;
      border-top: solid 1px rgba(0, 0, 0, 0.25);
      opacity: 0;
      padding-left: 2rem;
      padding-right: 2rem;
      justify-content: center;

      .inner-footer-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .credits {
          font-size: 0.7rem;
          color: white;
          a {
            color: white;
            font-weight: 600;
            &:hover {
              color:white;
              text-decoration: underline !important;
            }
          }
        }

      }

      ul {
        display: flex;
        justify-content: flex-start;

        li {
          //color: white;
          color: white;
          margin-right: 3rem !important;
          cursor: pointer;
          font-size: 0.7rem;
          font-weight: 400;

          *:hover, &:hover {
            text-decoration: underline;
          }

          a {
            color: white;
            &:hover{
              text-decoration: underline !important;
            }
          }
        }

      }

    }

  }

  .menu-inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 93vh;
    width: 100%;
    background-color: transparent;
    opacity: 0;
    display: flex;
    padding-top: 0;
    //padding-left: 48px;
    padding-left: 1rem;
    will-change: opacity;

    .chapitre {
      opacity: 0;
      &.no-img {
        .chapitre-wallpaper {
          background-size: 50%;
        }
      }
    }

    .chapitre-title {
      padding: 1.7rem;
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 0.2rem;
      background: white;
      font-size: 1.2em;
      font-weight: 500;
      line-height: 1.4rem;
      margin-top: 0 !important;
      color: black;
      padding-top: 1.3rem;
      display: flex;
      padding-top: 0.1rem;

      @media(max-width: 425px){
        font-size: 1.8em;
        line-height: 2rem;
        margin-top: 0.8rem !important;
      }

      @media(max-width: 1024px) {
        margin-top: 0.6rem !important;
      }

      &.no-read-yet {
        color: black;
      }
      &.readed {
        color: lighten(black, 70%);
      }
    }

    .categories {
      font-size: 1.4rem;
      color: #000;
      //margin-bottom: 0.5rem;
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      background-color: #dadada;

      .timeline-circle-category {
        position: absolute;
        top: 133px;
        transform: translate(1.08rem, -50%);
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        z-index: 0;
        overflow: hidden;

        @keyframes toLeft {
          0% {
            transform: translate(-4.4rem, 0px);
          }
          100% {
            transform: translate(-0.3rem, 0px);
          }
        }

        .reading-outer {
          margin-left: 1rem;
          transform: translateX(-1.7rem);
          display: flex;
          align-items: center;
          overflow: hidden;
          -webkit-backface-visibility: hidden;
          z-index: -1;
        }

        .reading {
          background: $red;
          color: white;
          padding: 0.13rem;
          padding-right: 0.7rem;
          padding-left: 1rem;
          border-radius: 1rem;
          font-size: 0.57rem;
          transform: translate(-4.4rem, 0px);
          z-index: 0;
          text-transform: uppercase;
          animation: toLeft 0.6s ease 2.5s forwards;
          -webkit-backface-visibility: hidden;
        }

        svg {
          z-index: -1;
          g {
            transform: translate(1px, 1px);
          }
        }
        text {
          color: white;
          line-height: 1.3rem;
          font-size: 1.1rem;
          font-weight: bold;
          transform: translate(-0.06rem, 0.32rem);
        }
        circle:first-child {
          fill: transparent;
          stroke: $red;
          stroke-width: 2;
          stroke-dasharray: 250;
          stroke-dashoffset: 250;
          transform: scale(-1, -1) translate(-42px, -42px);
          // transition: stroke-dashoffset 3s ease;
        }
        circle:nth-child(2){
          transform: translate(5px, 5px);
          // stroke: $red;
          fill: $red;
        }
      }

      .chapitre-wallpaper {
        display: block;
        height: 133px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .number {
        position: absolute;
        top: 0;
        left: 0;
        padding-left: 9px;
        color: #fff;
        font-weight: bold;
        font-size: 1.4rem;
        margin-right: 26px;
        transform: translate(22%, -23%);
        z-index: 0;

        .inner {
          background-color: #FA5057;
          width: 48px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom-left-radius: 3px;
          border-bottom-right-radius: 3px;
          border-top-right-radius: 3px;
        }

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 2px;
          width: 20px;
          height: 11px;
          background: #c53c41;
          clip-path: polygon(37% 0%, 100% 0%, 100% 100%, 0% 100%);
          z-index: -1;
        }

      }

    }

    .articles-outer {
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 20px;
      padding-top: 10px;
    }

    .articles {
      padding: 1.1rem;
      //height: 249px;
      height: 241px;
      margin: 0 auto;
      margin-bottom: 0;
      width: calc(100%);
      margin-top: 12px;
      padding-right: 21px !important;
      padding-top: 0;
      overflow-y: auto;
      padding-bottom: 10px;
      direction: ltr;
      background: white;

      .articles-after-list {
        margin-top: 0.8rem;
        border-top: solid 6px #dfdeec4f;
        padding-top: 0.3rem;
      }

      span.subcategory-title {
        background: rgba(151, 151, 151, 0.24);
        border-radius: 2px;
        padding: 0.5rem;
        padding-top: 0.38rem;
        padding-bottom: 0.38rem;
        display: block;
        color: rgba(0, 0, 0, 0.55);
        text-transform: uppercase;
        line-height: 0.85rem;
        margin-top: 0.2rem;
        font-size: 0.74rem;


        @media(max-width: 425px) {
          line-height: 1.5rem;
          margin-top: 1rem;
          font-size: 1.2rem;
        }

      }

      /*&:before {
        content: "";
        position: absolute;
        bottom: 0;
        height: 66px;
        background-image: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0.4), transparent);
        width: 100%;
        left: 0;
      }*/

      &::-webkit-scrollbar {
        width: 5px;
      }

      //1C4347

      &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.1);
      }

      &::-webkit-scrollbar-thumb {
        background: #000000;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #000000;
      }

      li {
        cursor: pointer;
        padding-bottom: 0.7rem;
        padding-top: 0.6rem;
        border-bottom: solid 1px #DFDEEC;

        &:hover {
          span {
            text-decoration: underline;
          }
        }

        &:last-child {
          border: 0;
        }

      }

      a {
        font-size: 0.81rem;
        font-weight: 400;
        //color: #969696;
        color: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        &.done {
          color: rgba(0, 0, 0, 0.55);
        }
        &:not(.done){
          color: black;
        }
        i {
          margin-right: 0.5rem;
          font-size: 1rem;
          transform: translate(0px, 2px);

          @media(max-width: 425px) {
            font-size: 1.6rem;
            margin-right: 1.2rem;
          }

          &.ico-pold-lu {
            color: #4EC575;
          }
          &.ico-pold-non_lu {
            //color: lighten(black, 70%);
            color: black;
          }

        }
        span {
          line-height: 1.1rem;
        }
      }

    }

    .articles-test {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0 auto;
      padding-bottom: 0;
      padding-top: 5rem;
      overflow-y: scroll;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: darken( #2FBDDA, 15% );
      }

      &::-webkit-scrollbar-thumb {
        background: white;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: lighten( black, 80% );
      }

      span.number {

      }

      > ul {
        width: 100%;
        // max-width: 1140px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > li {
          flex: 0 calc(100% / 4);
          // padding: 15px;
          padding: 9px;
          box-sizing: border-box;

          @media (min-width: 1700px) {
            flex: 0 calc(100% / 5);
          }

          div.inner {
            width: 100%;
            position: relative;
            background: white;
            box-shadow: 13px 29px 76px -20px rgba(0,0,0,0.1);
            border-radius: 6px;
            overflow: hidden;
          }

        }

      }

    }

  }

  .background-menu {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    //background: #4d49fc;
    //background: #2e3dcc;
    background: white;
    opacity: 0;
  }

}

@import "./menu-media.scss";
