@import "../../mixins.scss";

.pold {
  .couverture .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 7%;
    color: white;
    z-index: -1;

  }

  .couverture {
    h1 {
      font-size: calc(1.7vw + 1vh + 1vmin);
      position: relative;

      a {
        &:after {
          @include wait(#c6c5c5);
        }
      }

    }
  }

  .couverture {
    .frame .title {
      position: absolute;
      top: 0;
      left: 0;
      padding-left: 2rem;
      padding-top: 1rem;
      z-index: 3;
      img {
        //max-width: 450px;
        max-width: 50%;
        //width: 100%;
      }

      a {
        color: white;

        &:hover {
          text-decoration: none;
        }

      }

    }
  }

  .couverture {

    .frame {

      a {
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        &.read-more {
          position: relative;
          &:after {
            @include wait(#bab9b9);
          }
        }

        //&:hover {
        //
        //  .fx-img {
        //    background: rgba(0, 0, 0, 0.39);
        //    background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 27%);
        //    transition: all 0.3s ease-out;
        //  }
        //
        //  p {
        //    opacity: 1;
        //    transform: translateY(0px);
        //    height: auto;
        //    //transition: all 0.4s ease-out;
        //    transition: all 500ms cubic-bezier(0.000, 0.000, 0.000, 1.065);
        //
        //  }
        //
        //  h2 {
        //    font-size: 2.85rem;
        //    line-height: 3.2rem;
        //    transition: all 0.4s ease;
        //    transform: scale(0.87);
        //    transform-origin: top left;
        //  }
        //
        //}

      }

      p {
        display: flex;
        height: 0;
        overflow: hidden;
        transition: all 0.2s ease-out;
        transform: translateY(20px);
        opacity: 0;
        width: 70%;
        cursor: pointer;
      }

    }

  }

  .no-img {
    .article-couverture {
      background: #2FBDDA;
    }
  }

  .article-couverture {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    //background: #ececec;
    //background: #d1d1d1;
    background: #2FBDDA;

    .outer-i {
      display: flex;
      //&:after {
      //  @include wait(#b8b8b8);
      //}
    }

    .time {
      margin-right: 1.2rem;
    }

    .time, .status {
      //margin-top: 1em;
      //margin-top: 0.5em;
      font-size: 1rem;
      font-weight: 400;
      position: relative;
      display: flex;
      color: white;
      align-items: center;

      span {
        font-weight: 600;
        font-size: 0.9rem;
      }

      i {
        color: white;
        margin-right: 0.35rem;
      }

      @media( max-width: 425px) {
        font-size: 1.1rem;
        span {
          font-size: 1.1rem;
          line-height: 0.8rem;
        }
      }

    }

    .chapitre {
      font-size: 1.1rem;
      line-height: 1.1rem;
      color: white;
      // margin-bottom: 0.6rem;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      font-weight: 600 !important;
      position: relative;
      // background: #FB5058;
      padding: 0.2rem;
      padding-bottom: 0;
      padding-left: 0.3rem;

      @media(max-width: 768px) {
        align-items: flex-start;
        flex-direction: column;
      }

      .number {
        font-weight: 600;
        font-size: 1.2rem;
        margin-right: 0.5em;
        background: $red;
        padding: 0.2rem;
        height: 30px;
        width: 30px;
        border-radius:100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

    }

    h1, h2 {
      font-size: 3.5rem;
      line-height: 3.9rem;

      &.title {
        font-size: 6.5rem;
        line-height: 6.3rem;

        @media (max-width: 425px){
          font-size: 5rem;
          line-height: 5rem;
        }

      }

      width: 100%;
      transition: all 0.2s ease;
      cursor: pointer;
      transform-origin: top left;
      position: relative;
      display: inline;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      color: white;
      padding-left: 12px;
      padding-right: 12px;

      @media(max-width: 425px){
        font-size: 3rem;
        line-height: 3.2rem;
      }

      &:hover {
        color: white;
      }


      span {
        background: #2E6066;
        padding-top: 0;
        box-shadow: 12px 0 0 #2E6066, -12px 0 0 #2E6066;
        padding-bottom: 0.2rem;
        line-height: 4.1rem;

        &:nth-child(1) {
          font-size: 3.2rem;
          line-height: 3rem;
          padding-top: 0.2rem;
        }
        &:nth-child(3) {
          padding-bottom: 0.7rem;
        }

      }

      @media( max-width: 320px) {
        font-size: 2rem;
        line-height: 2.3rem;
      }

      &:after {
        @include wait(#ececec);
      }

    }


    > .inner {
      position: absolute;
      display: flex;
      bottom: 0;
      //width: calc(100% / 3 * 2);
      // width: calc(80%);
      width: calc(100% / 3 * 2);
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      color: white;
      z-index: 3;
      padding: 7%;
      padding-right: 0%;
      padding-left: 5%;

      > a > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

    }

  }

  .article-view {

    .article-couverture {
      > .inner {
        // padding: 5%;
        padding: 3%;
      }
    }

    .outer-i-i {
      display: flex;
      position: absolute;
      bottom: 0;
      right: 0;
      background: #2E6066;
      padding: 1.2rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      z-index: 11;

      .time {
        margin-right: 0;
        padding-right: 0.85rem;
        border-right: solid 2px rgba(151, 151, 151, 0.23);
      }

      .status {
        padding-left: 0.85rem;
      }

      .time, .status {
        margin-top: 0;
        font-size: 0.8rem;

        i {
          color: white;

          &.ico-pold-lu {
            color: #4ea8b3;
          }

        }

      }

    }

  }

  .chapitres {
    height: 22vh;
    display: flex;

    > div {
      width: 100%;
      height: 100%;
      display: flex;
    }

    .chapitre {
      display: flex;
      width: calc(100% / 3);
      height: 100%;
      border-right: solid 1px #D8D8D8;

      .time {
        color: black;
        i{
          color: black;
        }
      }

      &.readed {
        .chapitre-titre {
          color: lighten(black, 70%);
        }
        .title {
          color: lighten(black, 70%);
        }
        .time {
          color: lighten(black, 70%);
          i{
            color: lighten(black, 70%);
          }
        }
      }

      &:after {
        @include wait(#ececec);
        //transition: opacity 1s ease 0.5s, transform 0.1s 1s, -webkit-transform 0.1s 1s;
        //transition: opacity 1s ease 1s;
      }

      &:hover {
        background: #f3f3f3;
      }

      &:last-of-type {
        border: 0;
      }

      a {
        display: flex;
        flex-direction: column;
        color: black;
        padding: 7%;
        padding-right: 10%;
        //padding: 9%;
        padding-bottom: 0px;
        width: 100%;
        align-items: flex-start;

        &:hover {
          text-decoration: none;
        }

        .chapitre-titre {
          font-size: 0.8rem;
          line-height: 0.8rem;
          color: black;
          margin-bottom: 0.2rem;
          letter-spacing: 1px;
          display: flex;
          align-items: center;
          // height: 28px;
          text-transform: uppercase;
          font-weight: 600 !important;
          position: relative;
          padding: 0.2rem;
          padding-bottom: 0;

          &:after {
            @include wait(#eeeded)
          }

          .number {
            color: #fff;
            background:$red;
            padding: 0.2rem;
            padding-top: 5px;
            padding-right: 5px;
            transform: translateY(-1px);
            font-size: 0.8rem;
            font-weight: 900;
            border-radius: 100%;
            margin-right: 0.5rem;
            width: 1.1rem;
            padding-left: 0.27rem;
            height: 1.1rem;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .title {
          font-size: 1.15rem;
          line-height: 1.4rem;
          min-height: 2.4rem;
          //margin-bottom: 0.5rem;
          margin-top: 0.4rem;
          position: relative;
          color: black;
          font-weight: 400;

          &:after {
            @include wait(#ececec)
          }

        }

        .outer-i {
          display: flex;
        }

        .time {
          margin-right: 0.8rem;
        }

        .time, .status {
          //margin-top: 1em;
          margin-top: 0.9em;
          font-size: 0.84rem;
          font-weight: 400;
          position: relative;
          display: flex;
          align-items: center;


          i {
            color: black;
            margin-right: 0.35rem;

            &.ico-pold-lu {
              color: #4EC575;
            }
            &.ico-pold-non_lu {
              color: lighten(black, 70%);
            }

          }

          &:after {
            @include wait(#ececec)
          }

        }

      }

    }

  }

  .start-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    width: calc(100% / 3);
    z-index: 10;

    > div {
      width: 100%;
      box-sizing: border-box;
      background: #E54E10;
      display: flex;
      padding: 1.6em;
      padding-top: 1.3rem;
      padding-bottom: 1.2rem;
      border-radius: 0;
      color: white;
      justify-content: center !important;
      align-items: center !important;
      font-weight: 400;
      font-size: 1.45rem;

      .inner-start {
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          margin-left: 0.6rem;
          transform: translate(0px, 2px);
          font-size: 1.2rem;
          transition: all 0.3s ease;
        }

      }

      &:hover {
        color: white;
        background: darken(#E54E10, 10%);

        i {
          margin-left: 1rem;
          transform: translate(7px, 2px);
          font-size: 1.2rem;
          transition: all 0.6s ease;
        }

      }
    }

    &:after {
      @include wait(#d3d3d3);
    }

  }
}

@import "./couverture-media.scss";
