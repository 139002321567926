@import "../../mixins.scss";

.pold {
  .cible-view {
    //transition: all 0.40s cubic-bezier(0.53, -0.02, 0.21, 0.99);
    will-change: transform;
    position: relative;
    overflow: hidden;

    button.imprimer-btn, button.download-btn {
      margin-left: 0.7rem;
      bottom: 1rem;
      right: 1rem;
      div, a {
        width: 100%;
      }
    }

    &.no-img.article-view {
      //border-left: solid 1px rgb(14, 121, 175);
      border-left: solid 1px #28a1ba;
      background-color: #2FBDDA;
      margin-left: -2px;
      .fx-img{
        display: none;
      }
    }

    .status {
      cursor: pointer;
    }

    .infos {
      display: flex;
      margin-bottom: 0.8rem;

      @media (max-width: 425px){
        margin-bottom: 0.95rem;
        padding-left: 12px;
        padding-right: 12px;
      }

      .outer-i {
        margin-left: 2rem;

        @media (max-width: 768px) {
          padding-top: 2px;
          margin-left: 0;
          margin-top: 0.8rem;
        }

      }
    }

    @keyframes opacityImage {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    img, .fuck {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
      will-change: opacity;
      opacity: 0;
      //animation: opacityImage 2s ease forwards;

      &.loaded {
        opacity: 1;
        transition: opacity 0.6s ease 0.200s;
      }

    }

    h2 {
      color: white;
    }

    .chapitre {
      color: white;

      .category-block {
        display: flex;
        align-items: center;

        .number {
          background: #E54E10 !important;
        }

        .inner-titles {
          display: flex;
          flex-direction: column;
          text-transform: uppercase;
          font-weight: 500;
          .subcategory {
            font-size: 0.7rem;
          }
        }
      }

      .number {
        color: #fff;
        background: #f95e7d;
        padding: 0.2rem;
        padding-top: 7px;
        font-weight: 700;
        font-size: 22px;
        margin-right: 15px;
        min-width: 27px;
        min-height: 27px;
        border-radius:100%;
        display: flex;
        justify-content: center;
        align-items: center;


        @media(max-width: 425px) {
          padding-top: 3px;
          margin-right: 10px;
        }
      }

    }

    .inner-head {
      height: 100%;
      width: 100%;
      position: relative;

      &.not-ready{
        &:after {
          @include wait(#ececec)
        }
      }

      &:after {
        @include wait(#ececec)
      }

      @keyframes animTest {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      img {
        opacity: 0;

        &.active {
          opacity: 1;
        }
        &.loaded {
          opacity: 1;
          transition: opacity 0.6s ease 0.200s;
        }


      }

      &.ready {
        //
        //.fx-img {
        //  opacity: 1;
        //}
        //
        //img {
        //  opacity: 1;
        //}

      }

    }

    .fx-img {
      //display: none !important;
      top: -74%;
      left: -100%;
      width: 200%;
      height: 200%;
      content: "";
      position: absolute;
      //opacity: 1;
      // background: rgba(0, 0, 0, 0);
      background: rgba(0, 0, 0, 0.45);
      //background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 27%);
      z-index: 2;
      //transition: all 0.2s ease-out;
      will-change: opacity;
      opacity: 0;
      //animation: opacityImage 2s ease forwards;

      &.loaded {
        opacity: 1;
        transition: opacity 0.6s ease 0.200s;
      }
    }

    .imageView {
      width: 101%;
      height: 101%;
      will-change: transform;
      object-fit: cover;
      top: 0;
      left: 0;
      position: absolute;

      img {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        object-fit: cover;
        will-change: opacity;
        opacity: 0;
      }
      //transition: all 0.40s cubic-bezier(0.53, -0.02, 0.21, 0.99) !important;
    }

    .start-btn {
      will-change: transform;
      //transition: all 0.50s ease-in-out 0.20s !important;
    }

    &.article-view {
      // height: 78vh !important;
      //transform: matrix(0.99999, 0, 0, 0.99999, 0, -200);
      will-change: height;
      // transition: height 0.4s ease;
      height: 600px;

      .inner {
        width: 100%;

        @media (max-width: 420px) {
          margin-bottom: 5%;
          bottom: 0%;
        }

      }

      @media (max-width: 1024px) {
        height: 65vh !important;
        &:not(.no-img) {
          height: 65vh !important;
        }
      }

      @media (max-width: 375px) {
        height: 82vh !important;
        &:not(.no-img) {
          height: 82vh !important;
        }
      }

      &.breves {
        //height: 60vh !important;
        //transform: matrix(0.99999, 0, 0, 0.99999, 0, -200);

      }

      /*&.no-img {
        h1, h2 {
          color:black;
        }
        .chapitre {
          color: black;
        }
        .time, .status {
          color: black;
        }
        .time i, .status i {
          color: black;
        }
      }*/

      .start-btn {
        opacity: 0;
      }

      h2 {
        width: 100% !important;
      }

      img, .fx-img{
        //transform: translateY(200px);
        //transition: all 0.40s cubic-bezier(0.53, -0.02, 0.21, 0.99) !important;
      }

      .fx-img {
        //transform: translateY(200px);
      }

      .start-btn {
        //transform: translateY(101%);
        //transform-origin: bottom;
      }
    }
    &.couverture-view {
      height: 78vh !important;

      .imprimer-btn {
        display: none;
      }

      @media (max-width: 375px) {
        height: 72vh !important;
      }

      .time, .status {
        /*&:after {
          @include wait(#ececec)
        }*/
      }

      .start-btn {
        //transform: translateY(0%);
        //transform-origin: bottom;
        @media (max-width: 768px) {
          width: auto;
        }
      }

    }
  }
}

.pold {
  button.imprimer-btn, button.download-btn {
    display: flex;
    position: absolute;
    border-radius: 100%;
    z-index: 10;
    width: 2rem;
    height: 2rem;
    overflow: hidden;
    align-items: center;
    justify-content: center;

    .downloadIcon {
      transform: scale(0.9);
    }
    .loadingIcon {
      transform: scale(1.2);
    }

    &.activeDownload {
      background: #E54E10 !important;
      svg {
        g {
          fill: white;
        }
      }
    }

    svg {
      width: 80%;
      height: 80%;
    }

    @media(max-width: 768px) {
      right: 15px;
      display: none;
    }

    /*div, a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 100%;
        height:100%;
      }
    }*/

  }
}
