@import "../../../mixins.scss";

.pold {
  .main .header {
    position: fixed;
    top: 0;
    display: flex;
    right: 0;
    //width: calc(100% - 120px);
    width: calc(100% - 87px);
    justify-content: flex-end;
    background: transparent;
    //transition: all 0.15s ease;
    padding-right: 1.7rem;
    //z-index: 100;
    z-index: 1000000;
    height: 87px;
    transform: translate3d(0,0,0);

    @media( max-width: 425px) {
      padding-right: 4.7rem;
    }

    .impressionIcon {
      float: left;
      margin-top: -0.2rem;
      margin-right: 0.4rem;
      background-color: white;
      border-radius: 50%;
      padding: 0.4rem;
      min-height: 1.8rem;
      min-width: 1.8rem;
    }
    
    .imprimer-btn {
      position: relative;
      display: none;
      margin-right: 1rem;
      a, div {
        display: flex;
        justify-content: center;
      }
    }

    &.scrolled:not(.menuShow) {
      .imprimer-btn,.download-btn svg {
        background: black;
        color: white;
        g{fill: white;}
      }
    }

    &.scrolled:not(.isSearching):not(.menuShow), &.no-img:not(.isSearching) {
      .searchBtn {
        background: black !important;

        i:before {
          color: white !important;
        }

      }
    }

    &.scrolled:not(.isSearching), &.no-img:not(.isSearching) {
      background: white;

      @media( max-width: 425px) {
        .searchBtn {
          background: white !important;

          i:before {
            color: black !important;
          }

        }
      }

    }

    &.menuShow {
      background: #2FBDDA !important;
      .imprimer-btn,.download-btn {
        display: block;
        background: white;

        @media(max-width: 425px) {
          display: none;
        }
      }
    }

    &.no-img:not(.scrolled):not(.menuShow) {
      background: #2FBDDA !important;
      //border-left: solid 1px rgb(14, 121, 175);
    }
    &.isSearching {
      background: white !important;
    }

    &.isPage {
      border-bottom: solid 1px #E9E9E9;
      &.menuShow {
        border-bottom: solid 1px transparent;
      }
      &:not(.menuShow) {
        .inner .right-bloc .searchBtn.isSearching {
          border: solid 1px black !important;
          &:hover {
            border: solid 1px black !important;
          }
        }
      }
    }

    .fx-backgrd {
      position: absolute;
      opacity: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
      z-index: -1;

      &:after {
        //@include wait(#ececec)
        @include wait(#828282);
      }

    }

    .scrollbar-container {
      height: 200px;
    }

    .accessibilite {
      display: flex;
      align-items: center;
      //margin-right: 1.6rem;
      margin-right: 0;
      transform: translateY(4px);

      @media( max-width: 768px){
        margin-right: 0.7rem;
      }

      button {
        background: transparent;
        border:0 !important;
        font-size: 0.75rem;
        color: white;
        &:hover {
          text-decoration: underline;
        }
      }
      span {
        color:white;
      }
      @media( max-width: 425px) {
        margin-right: 0;
        transform: translateY(1px);

        button, span {
          font-size: 1.1rem;
          padding-left: 2px;
          padding-right: 2px;
        }

      }
    }

    .menu-numero {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      //margin-top: 0.3rem;
      overflow: visible !important;
      //border: 1px solid #ffffff;
      border-radius: 3px;

      button {
        border: 1px solid transparent !important;
        border-radius: 5px;
      }

      &:after {
        @include wait(#ececec);
      }

      .numeros-menu-outer {
        width: calc(100% + 0px);
        left: 0;
        // border-top: solid 1px transparent;
        box-shadow: 0 4px 15px 0 rgba(15,15,89,0.10);

        .ps__thumb-y {
          background-color: #ff4a7a;
          margin-right: 5px;
        }

        /*.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
          margin-right: 5px;
        }*/

      }

      .show-more-btn {
        text-align: center;
        padding-top: 0.5rem;
        padding-bottom: 0.4rem;
        font-size: 0.6rem;
        font-weight: 600;
      }

      .numeros-menu {
        height: auto;
        overflow-y: auto;
        margin-bottom: 0px;

        &.show-more {
          height: 150px;
          overflow-y: scroll;
        }

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: #fff;
        }

        &::-webkit-scrollbar-thumb {
          background: $red;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #4E49FC;
        }

      }

      &.menuShow {
        color: black;

        .menu-numeros-btn {
          background: white;
          color: black;
          border: 1px solid white !important;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }

        //&.scrolled {
        //  border: 1px solid #f95e7d !important;
        //}

        .menu-numeros-btn {
          color: black;
          text-decoration: none;

          i {
            color: #0F0F59;
          }

        }

        .numeros-menu-outer {
          //border-top: solid 1px rgba(151, 151, 151, 0.12156862745098039);
        }

      }

    }

    .scroll-block {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 6px;
      -webkit-transform: translateY(100%);
      transform: translateY(0%);
      border-top: solid 0px #dbdbdb;

    //> div:first-child {
      //  flex-basis: 272px;
      //}

      .scroll-inner {
        min-width: 400px;
        width: 100%;
        margin: 0 auto;
        max-width: 900px;
        background: #dbdbdb;
        display: none;

        @media (max-width: 768px){
          display: flex;
        }

        @media (max-width: 1025px) and (min-height: 900px){
          display: flex;
        }

        .scroll-fx {
          width: 0px;
          height: 100%;
          // background: #090101;
          background: #2fbdda;
        }

      }

    }

    &.scrolled:not(.menuShow) {
      .scroll-block {
        display: flex;
      }
    }

    &.scrolled:not(.menuShow) {
      .accessibilite {
        button, span {
          color: black !important;
        }
      }
    }

    &.scrolled, &.no-img, &.menuShow, &.isSearching {
      background: #2FBDDA;
      transition: all 0.15s ease;

      @media (max-width: 425px){
        //border-bottom: solid 1px #e6e6e6;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 1px;
          // background: #e6e6e6;
          background: rgba(0, 0, 0, 0.05);
          left: 0;
        }
      }

      .accessibilite {
        button, span {
          color: white;
        }
      }

      .menu-numero {
        // button { border-color: black !important; }

        .inner {
          &:after {
            background: black;
          }
        }
        i {
          &:before {
            color: black;
          }
        }
      }

      .menu-numero.menuShow {
        box-shadow: 0 4px 15px 0 rgba(15, 15, 89, 0.1);
        .inner {
          &:after {
            background: white;
          }
        }
      }

        .menu-numeros-btn {
        color: black;
      }

      .inner {
        h1 {
          a {
            color: black;
          }
        }
      }

    }

    .inner {
      display: flex;
      justify-items: center;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      background: transparent;

      .right-bloc {
        display: flex;
        > div {
          display: flex;
          align-items: center;
        }

        .searchBtn {
          position: relative;
          background: white;
          border-radius: 100%;
          font-size: 1.3rem;
          width: 1.6rem;
          justify-content: center;
          align-items: center;
          display: flex;
          height: 1.6rem;
          margin-left: 1.8rem;
          border: solid 1px white;
          transform: scale(1);
          padding:0;

          @media( max-width: 768px){
            margin-left: 0.5rem;
          }

          @media( max-width: 425px) {
            font-size: 1.6rem;
            width: 2.2rem;
            height: 2.2rem;
            margin-left: 1rem;
            padding: 0;

            i {
              transform: translate(0, 1px) !important;
            }

          }

          &:after {
            @include wait(#ececec);
          }

          i {
            display: flex;
            &:before {
              color: black;
            }
          }


          &.isSearching {
            border: solid 1px #2FBDDA !important;
            transform: scale(1.2);
            transition: transform 0.6s ease;

            &:hover {
              border: solid 1px #2FBDDA !important;
              background: #2FBDDA;
              i {
                &:before {
                  color: white;
                }
              }
            }

            i {
              font-size: 0.65rem;
              transform: translate(0, 1px);
              &:before {
                color: #2FBDDA;
              }
            }
          }

        }

      }

      .logo {
        img {
          width: 130px;
        }

        &:after {
          @include wait(#dbdbdb);
        }

      }

      h1 {
        padding-left: 1.7rem;
        //font-size: 1.5rem;
        margin-bottom: 0;
        font-size: 1.3rem;
        font-weight: 600;
        text-transform: uppercase;

        a {
          color: white;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          > div {
            font-size: 1.3rem;
            font-weight: 400;
            line-height: 2.3rem;
            text-transform: uppercase;
          }

          img {
            height: 3.1rem;
            margin-right: 0.4rem;
            width: auto;
          }

          &:after {
            // @include wait(#ececec);
          }

        }
      }

    }

    .menu-toggle-btn {
      background: #4e49fc;
      color: white;
      border: 0;
      outline: none;
      width: 100px;
      height: 90px;
      margin-left: 3em;
      cursor: pointer;
    }

  }

  .menu-numeros-btn {
    font-size: 0.9rem;
    font-weight: 600;
    //padding: 0.9rem;
    //padding-top: 0.6rem;
    //padding-bottom: 0.6rem;
    //padding-right: 0.9rem;
    font-weight: 400;
    text-transform: uppercase;
    padding: 1.3rem;
    padding-top: 0.8rem;
    padding-bottom: 0.5rem;
    line-height: 0px;
    padding-right: 1.3rem;
    background: transparent;
    cursor: pointer;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;

    @media(max-width: 768px){
      // max-width: 291px;
      min-width: 260px;
    }

    .inner {
      display: flex;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        transform: translateY(0.4rem);
        background: white;
        height: 1px;
      }

    }

    span {
      margin-right: 3rem;
      font-size: 0.76rem;
      position: relative;
      display: block;
      @media(max-width: 768px){
        margin-right: 0;
      }
    }

    i {
      color: #fff;
      font-size: 1rem;
      &.ico-pold-chevron-up {
        transform: translateY(-2px);
      }
    }

  }

  .numeros-menu-outer {
    position: absolute;
    //background: #f1f1f1;
    background: white;
    padding: 0px;
    padding-bottom: 0.5rem !important;
    z-index: 1;
    // bottom: 0rem;
    bottom: 1px;
    left: 0;
    transform: translateY(100%);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .inner-numero-menu {
      /*max-height: 150px;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;*/
    }

  }

  .numeros-menu {

    .inner-numero-menu {
      max-height: 150px;
    }

    li {
      display: flex;
      margin-bottom: 0;
      align-items: center;
      cursor: pointer;

      &:last-child {
        border-bottom: 0;
      }

      &:hover {
        background: rgba(163, 163, 163, 0.05);
      }

      &.current {
        background: rgba(163, 163, 163, 0.1);
        border-bottom: solid 1px transparent;

        a {
          font-weight: 600;
          display: flex;
          justify-content: space-between;
          align-items: center;

          i {
            color: $red;
            padding-right: 0.2rem;
            font-size: 1rem;
          }

        }

      }

    }

    a {
      color: black;
      text-transform: uppercase;
      font-size: 0.75rem;
      line-height: 1.2rem;
      padding: 7%;
      padding-left: 10%;
      width: 100%;
    }

  }
}

@import "./header-media.scss";
