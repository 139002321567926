@font-face {
  font-family: 'icomoon-pold';
  src:  url('icomoon-pold.eot?xmo4rz');
  src:  url('icomoon-pold.eot?xmo4rz#iefix') format('embedded-opentype'),
  url('icomoon-pold.ttf?xmo4rz') format('truetype'),
  url('icomoon-pold.woff?xmo4rz') format('woff'),
  url('icomoon-pold.svg?xmo4rz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ico-pold-"], [class*=" ico-pold-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-pold' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-pold-lu:before {
  content: "\e906";
}
.ico-pold-non_lu:before {
  content: "\e908";
}
.ico-pold-time:before {
  content: "\e90b";
}
.ico-pold-close:before {
  content: "\e90c";
}
.ico-pold-burger:before {
  content: "\e900";
}
.ico-pold-chevron-down:before {
  content: "\e901";
}
.ico-pold-chevron-left:before {
  content: "\e902";
}
.ico-pold-chevron-right:before {
  content: "\e903";
}
.ico-pold-chevron-up:before {
  content: "\e904";
}
.ico-pold-next-article:before {
  content: "\e905";
}
.ico-pold-prev-article:before {
  content: "\e907";
}
.ico-pold-search:before {
  content: "\e909";
}
.ico-pold-reading:before, .ico-pold-start-reading:before {
  content: "\e90a";
}
